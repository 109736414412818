<template>
  <div class="login">
    <!-- 头部 -->
    <!-- <div class='header'>
      <div class='flex align-center hp100 header_box bbox'>
        <img src='../assets/logo.png' alt='' width='240' height='20' />
        <div class='f20 text ml10'>登录</div>
      </div>
    </div> -->
    <!-- 登录 -->
    <div class="login_box wp100 bbox positionr main_box">
      <div class="login_form positiona">
        <div class="form_tit textc f18">账号密码登录</div>
        <div class="form_group">
          <!-- <label class='dblock f16 mb10'>用户名</label> -->
          <input type="text" placeholder="输入用户名" v-model="username" />
        </div>
        <div class="form_group">
          <!-- <label class='dblock f16 mb10'>密码</label> -->
          <input
            type="password"
            placeholder="输入密码"
            v-model="password"
            @keyup.enter="enterSubmit"
          />
        </div>
        <div class="form_group">
          <!-- <label class='dblock f16 mb10'>验证码</label> -->
          <!-- <div class='flex'>
            <input type='text' placeholder='输入验证码' v-model='code' />
            <div class='ml20'>
              <img src='../assets/code.png' alt='' height='40' width='120' />
            </div>
          </div> -->
        </div>
        <div class="login_btn">
          <button class="f16 pointer fw700" @click="submit">
            登 录
          </button>
        </div>
        <!-- <div class='flex justify-between form_group'>
          <div class='text999 f12 pointer'>忘记密码</div>
          <div class='text-blue f12 pointer'>免费注册</div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      code: "",
      redirect_url: "",
      auth_url: ""
    };
  },
  methods: {
    // 登录
    submit() {
      var that = this;

      if (!that.username) {
        that.$message({
          message: "请输入用户名",
          type: "warning"
        });
        return;
      }
      if (!that.password) {
        that.$message({
          message: "请输入密码",
          type: "warning"
        });
        return;
      }
      console.log(that.username);
      console.log(that.$md5(that.password));
      //登陆到首页
      that.$http
        .post("/userInfo/login", {
          loginName: that.username,
          password: that.$md5(that.password)
        })
        .then(response => {
          if (response.data.code==200) {
             that.setLocalStorage(
            "token",
            response.data.data.token,
            120 * 60 * 1000
          );
          that.setLocalStorage("loginName", that.username, 120 * 60 * 1000);
          if (that.redirect_url != "" && that.redirect_url != undefined) {
            // 跳转到子系统
            that.genTikect();
          } else {
            that.$store.commit("createUserName", that.username);
            localStorage.setItem("userName", JSON.stringify(that.username));
            that.username = "";
            that.password = "";
            // that.$router.push({ path: "/index" });
            that.$router.push({path:'/member'});
          }
          } else {
            this.$message({
            message: "账号或密码错误！",
            type: "warning",
          });
          }
         
        })
        // .catch(function(error) {
        //   that.$message({
        //     message: "提交失败",
        //     type: "warn"
        //   });
        //   console.log(error);
        // });
    },
    // 回车事件
    enterSubmit() {
      this.submit();
    },
    genTikect() {
      var that = this;
      this.$http
        .post("/auth/genTicket", {
          loginName: this.username
        })
        .then(function(response) {
          console.log(response);
          that.ticket = response.data.data;
          var url = decodeURIComponent(that.auth_url);
          window.location =
            url +
            "?source=trilateral&redirect_url=" +
            that.redirect_url +
            "&ticket=" +
            that.ticket;
        });
    },
    setLocalStorage(key, value, expires) {
      // 保持到LocalStorage,expires单位是毫秒
      // expires = expires * 1000;
      let params = { key, value, expires };
      if (expires) {
        // 记录何时将值存入缓存，毫秒级
        var data = Object.assign(params, { startTime: new Date().getTime() });
        localStorage.setItem(key, JSON.stringify(data));
      } else {
        if (Object.prototype.toString.call(value) == "[object Object]") {
          value = JSON.stringify(value);
        }
        if (Object.prototype.toString.call(value) == "[object Array]") {
          value = JSON.stringify(value);
        }
        localStorage.setItem(key, value);
      }
    }
  },
  created() {
    var redirect_url = this.$route.query.redirect_url;
    var auth_url = this.$route.query.auth_url;
    console.log(redirect_url);
    //  this.redirect_url= this.getQueryVariable('redirect_url');
    //  this.auth_url= this.getQueryVariable('auth_url');
    this.redirect_url = redirect_url;
    this.auth_url = auth_url;
  }
};
</script>

<style scoped>
.ml10 {
  margin-left: 12px;
}
.header {
  height: 70px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.header_box {
  margin-left: 16%;
}
.main_box {
  height: 100vh;
}
.header .text {
  padding: 0 10px;
  border-left: 1px solid #999;
}

.login_box {
  background-image: url("../assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.login_box .login_form {
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  left: 50%;
  background-color: #ffffff;
  width: 25%;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.09);
  padding-bottom: 30px;
}
.login_form .form_tit {
  margin-top: 10%;
}
.login_form .form_group {
  width: 65%;
  margin: 18px auto 0 auto;
}
.form_group input {
  width: 100%;
  height: 42px;
  border: 1px solid #d7d8d9;
  padding-left: 15px;
  font-size: 12px;
  box-sizing: border-box;
  outline: none;
}
.form_group input ::placeholder {
  color: #333 !important;
}
.form_group input:hover {
  border: 1px solid #fc6507;
}
.form_group input:focus {
  border: 1px solid #fc6507;
}
.login_btn {
  height: 45px;
  background: #fc6507;
  border-radius: 5px;
  width: 65%;
  transition: all 0.3s;
  margin: 0 auto;
  margin-top: 30px;
}
.login_btn button {
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: transparent;
  outline: none;
  border: none;
}
.login_btn:hover {
  transform: translateY(-6px);
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1440px) {
  .login_box .login_form {
    width: 30%;
  }
  .login_form .form_group {
    margin: 15px auto 0 auto;
  }
  .login_btn {
    margin-top: 20px;
  }
}
</style>
